import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/linkedin-campaign.jpg"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function LinkedInPaid({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="LinkedIn Ad Campaign Structure" 
       description="This document gives you an overview of the basics of LinkedIn paid - how it works, audience types, campaign and objective types, types of ads and guidelines, as well as a detailed setup / mapping of an ad campaign, to help you build your strategy & creatives."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/linkedin-ad-campaign-structure/">LinkedIn Ad Campaign Structure</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="LinkedIn Ad Campaign Structure" />
        <SubHeading subheading="This document gives you an overview of the basics of LinkedIn paid advertising." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/linkedin-campaign-strategy" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="LinkedIn Ad Campaign Structure" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="How campaigns work, audience types, campaign and objective types, types of ads and guidelines, as well as a detailed setup / mapping of an ad campaign, to help you build your strategy & creatives." />
          <GumroadForm 
                formName="LinkedIn Ad Campaign Structure"
                redirectPage="https://socontent.gumroad.com/l/linkedin-campaign-strategy"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/linkedin-campaign-strategy"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default LinkedInPaid